<template>
    <div id="app">
        <Loading ref="loading"></Loading>
        <div class="header" v-if="userInfo">
            <div class="wrapper">
                <div class="logo">
                    <h2>
                        <font color="#07AA71">AD</font>STAT
                    </h2>
                </div>
                <div class="nav">
                    <div class="nav-list">
                        <div class="nav-item">
                            <router-link to="/accounts" class="nav-link"><span class="text">推广管理</span></router-link>
                            <div class="menu">
                                <ul>
                                    <li><a href="/accounts">推广账户</a></li>
                                    <li><a href="/account_reports">推广报表</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="user-profile">
                        <div class="user-info">
                            <img src="" class="avatar">
                            <div class="user-details">
                                <strong>{{ userInfo.name }}({{ userInfo.username }})</strong>
                                <small>{{ userInfo.role_name }}</small>
                            </div>
                        </div>
                        <div class="menu">
                            <ul>
                                <li><a href="/profile">个人资料</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="wrapper">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from './components/Loading.vue'
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'App',
    components: {
        Loading
    },
    computed: {
        ...mapState(['isLoading', 'isLoggedIn', 'userInfo'])
    },
    methods: {
        ...mapMutations(['setLoggedIn']),
        logout() {
            this.setLoggedIn(null)
            this.$router.push('/login')
        },
        setHtmlFontSize() {
            let width = document.body.clientWidth
            let fontsize
            if (width <= 240) {
                fontsize = 13.33
            } else if (width >= 540) {
                fontsize = 16
            } else {
                fontsize = (width / 18).toFixed(2)
            }
            document.getElementsByTagName("html")[0].style.fontSize = `${fontsize}px`
        }
    },
    mounted() {
        document.title = process.env.VUE_APP_TITLE
        window.addEventListener("DOMContentLoaded", this.setHtmlFontSize)
        window.addEventListener("resize", this.setHtmlFontSize)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.setHtmlFontSize)
        window.removeEventListener("DOMContentLoaded", this.setHtmlFontSize)
    },
    watch: {
        isLoading(newVal) {
            if (newVal) {
                this.$refs.loading.start();
            } else {
                this.$refs.loading.done();
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/sass/app.scss';
</style>
