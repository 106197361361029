<template>
    <div>
        <div class="page-header">
            <h3>转化记录</h3>
            <div class="actions">
                <span class="btn btn-light" @click="$router.back()">返回</span>
            </div>
        </div>
        <form class="form" @submit.prevent="onQuery">
            <div class="form-item">
                <label>转化类型:</label>
                <Selection :items="{ '2': '注册', '3': '付费', '4': '退款' }" placeholder="全部" v-model="query.type"
                    width="120">
                </Selection>
            </div>
            <div class="form-item">
                <label>开始时间:</label>
                <input type="date" class="form-control" name="start_date" v-model="query.start_date">
            </div>
            <div class="form-item">
                <label>结束时间:</label>
                <input type="date" class="form-control" name="end_date" v-model="query.end_date">
            </div>
            <div class="form-item">
                <button type="submit" class="btn btn-primary">查询</button>
                <button type="button" class="btn btn-light" @click="onClear">清空</button>
            </div>
        </form>
        <div class="table table-fixed">
            <table>
                <tr>
                    <th width="136" class="fixed-left">上报时间</th>
                    <th width="100">相关ID</th>
                    <th width="140">类型</th>
                    <th width="100">值</th>
                    <th width="120"></th>
                    <th width="300">关键词</th>
                    <th width="300">搜索词</th>
                    <th width="150">访问时间</th>
                    <th width="136" class="fixed-right">推送时间</th>
                </tr>
                <tr v-for="(item, index) in list" :key="index">
                    <td class="fixed-left">{{ item.time }}</td>
                    <td>{{ item.related_id }}</td>
                    <td><span class="v-middle">{{ item.type | conversionType }}</span><span class="badge text-bg-light"
                            v-if="item.is_test">测试</span></td>
                    <td>{{ item.value }}</td>
                    <td><button class="btn btn-light btn-s" @click="handleItemOcpcPush(item.id)"
                            v-if="item.type == 2 && item.logid_url">补推付费</button></td>
                    <td>{{ item.from_word }}</td>
                    <td>{{ item.search_word }}</td>
                    <td>{{ item.visit_time }}</td>
                    <td class="fixed-right">{{ item.ocpc_pushat_at }}</td>
                </tr>
            </table>
        </div>
        <div class="page-info">
            <Pagination :total_count="count" :current_page="query.page" :page_size="query.page_size"
                @handlePage="handlePage" @handlePageSize="handlePageSize"></Pagination>
        </div>
    </div>
</template>

<script>
import { formatDate } from '@/filters'
import { mapMutations } from 'vuex'

import Selection from '../../components/Selection.vue'
import Pagination from '../../components/Pagination.vue'

export default {
    name: 'Conversion',
    components: {
        Selection,
        Pagination
    },
    data() {
        return {
            query: {},
            list: [],
            count: 0
        }
    },
    mounted() {
        this.query = { ...this.getDefaultQuery(), ...this.$route.query }
        this.fetchList()
    },
    methods: {
        ...mapMutations(['startLoading', 'finishLoading']),
        getDefaultQuery() {
            let query = {
                type: 3,
                page: 1,
                page_size: 10
            }
            const now = new Date()
            query.end_date = query.start_date = formatDate(now)
            return query
        },
        handlePage(page) {
            this.query.page = page
            this.fetchList()
        },
        handlePageSize(pageSize) {
            this.query.page_size = pageSize
            this.fetchList()
        },
        fetchList() {
            this.startLoading()
            this.sum = null
            this.$api.post('conversion/list', this.query).then(res => {
                if (res.data.success) {
                    this.list = res.data.data.list
                    this.count = res.data.data.count
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error.message)
            }).finally(() => {
                this.finishLoading()
            })
        },
        onQuery() {
            this.handlePage(1)
        },
        onClear() {
            this.query = { ...this.getDefaultQuery(), ...this.$route.query }
            this.fetchList()
        },
        handleItemOcpcPush(id) {
            this.$api.post('conversion/ocpc_push', { conversion_id: id, new_type: 10 }).then(res => {
                if (res.data.success) {
                    alert('推送成功')
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error.message)
            }).finally(() => {
            })
        }
    }
}
</script>